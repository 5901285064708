import Phaser from 'phaser'
import Hero from '../game/Hero'

import { createHeroAnims } from '../game/HeroAnims'
import { createGhostAnims } from '../game/GhostAnims'

import '../game/Hero'
import '../game/Ghost'

import ScatterAI from '~/game/ghost-ai/ScatterAI'
import ChaseHeroAI from '~/game/ghost-ai/ChaseHeroAI'
import InterceptHeroAI from '~/game/ghost-ai/InterceptHeroAI'
import FlankHeroAI from '../game/ghost-ai/FlankHeroAI'
import PlayfullyChaseHeroAI from '~/game/ghost-ai/PlayfullyChaseHeroAI'
import HeroAI from '~/game/HeroAI'
import Ghost from '../game/Ghost'
import { borkTrait, handleGameEnd } from '../game/ghost-ai/consts/io'
import { setTriesRemaining } from '../game/ghost-ai/consts/gameState'

let eatDotCount = 0

let triesRemaining = 3
if(borkTrait == 'Houdini') {
	triesRemaining = 4
}

export default class Game extends Phaser.Scene
{
	private hero?: Hero
	private boardLayer?: Phaser.Tilemaps.DynamicTilemapLayer
	private cursors!: Phaser.Types.Input.Keyboard.CursorKeys
	private wsad!: {
        up: Phaser.Input.Keyboard.Key,
        down: Phaser.Input.Keyboard.Key,
        left: Phaser.Input.Keyboard.Key,
        right: Phaser.Input.Keyboard.Key
    }
	private spawnPoint: { x: number, y: number }
	private ghostGroup!: Phaser.Physics.Arcade.Group
	private livesText!: Phaser.GameObjects.Text
	private gameOver = false
	private lastMobileDirection: { up: boolean, down: boolean, left: boolean, right: boolean }
	private buttons!: { up: Phaser.GameObjects.Rectangle, down: Phaser.GameObjects.Rectangle, left: Phaser.GameObjects.Rectangle, right: Phaser.GameObjects.Rectangle }

	constructor() {
		super('game')
		this.spawnPoint = { x: 304, y: 144 }
		this.lastMobileDirection = { up: false, down: false, left: false, right: false }
	}

	init()
	{
		this.cursors = this.input.keyboard.createCursorKeys()

		this.wsad = {
			up: this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.W),
			down: this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.S),
			left: this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.A),
			right: this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.D)
		}
	}

	preload()
    {
		this.load.tilemapTiledJSON('tilemap', 'levels/level-1.json')
    }

    create()
    {
		const map = this.make.tilemap({ key: 'tilemap' })
		const tileset = map.addTilesetImage('basic_tiles', 'tiles')

		this.physics.world.setFPS(120)
		
		this.boardLayer = map.createDynamicLayer('Board', tileset)
			.forEachTile((tile: Phaser.Tilemaps.Tile) => {
				tile.tint = 0x4027fe
			})
			.setCollisionByProperty({ collides: true })

		const dotsLayer = map.createDynamicLayer('Dots', tileset)
		const dots = dotsLayer.createFromTiles(33, -1, { key: 'tiles', frame: 'white-dot-small.png', origin: 0 })
		dots.forEach(dot => {
			this.physics.add.existing(dot)
			const body = dot.body as Phaser.Physics.Arcade.Body
			body.setCircle(4, 12, 12)
		})

		const powerDots = dotsLayer.createFromTiles(34, -1, { key: 'tiles', frame: 'white-dot.png', origin: 0 })
		powerDots.forEach(dot => {
			this.physics.add.existing(dot)
			const body = dot.body as Phaser.Physics.Arcade.Body
			body.setCircle(8, 8, 8)

			dot.setTint(0xfe03f0)

			this.tweens.add({
				targets: dot,
				alpha: 0,
				duration: 1000,
				yoyo: true,
				repeat: -1
			})
		})

		createHeroAnims(this.anims)
		createGhostAnims(this.anims)

		this.createFromObjectsLayer(map.getObjectLayer('BoardObjects'))
		
		this.ghostGroup = this.createGhosts()

		if (this.hero)
		{
			this.physics.add.overlap(this.hero, dots, this.handlePlayerEatDot, this.processPlayerEatDot, this)
			this.physics.add.overlap(this.hero, powerDots, this.handlePlayerEatPowerDot, this.processPlayerEatDot, this)
		}

		this.livesText = this.add.text(263, 194, 'Lives: ' + triesRemaining, { fontFamily: 'Arial', fontSize: '24px', color: '#ffffff' })
		
		if(borkTrait == 'Maverick'){
			this.add.text(28, 8, borkTrait + ' = Slower Enemies', { fontFamily: 'Arial', fontSize: '14px', color: '#ffffff' })
		} else if(borkTrait == 'Houdini'){
			this.add.text(28, 8, borkTrait + ' = Extra Life', { fontFamily: 'Arial', fontSize: '14px', color: '#ffffff' })
		} else if(borkTrait == 'Junk Collector'){
			this.add.text(28, 8, borkTrait + ' = Extra Point', { fontFamily: 'Arial', fontSize: '14px', color: '#ffffff' })
		}
		
		this.createMobileControls()
	}

	private handleHeroGhostCollision(hero: Phaser.GameObjects.GameObject, ghost: Phaser.GameObjects.GameObject) 
	{
		if (this.hero && !this.gameOver)
		{
			if (triesRemaining == 1){
				this.gameOver = true
				this.hero.setTint(0xff0000)
				this.livesText.destroy()
				this.add.text(253, 194, 'You Lose!', { fontFamily: 'Arial', fontSize: '24px', color: '#ffffff' })
				this.scene.pause()
				triesRemaining = 0
				setTriesRemaining(triesRemaining)
				handleGameEnd()
				setTimeout(() => {
					this.scene.stop()
				}, 10000)
			} else {
				this.hero.resetHero() // Reset the hero when respawning
				this.ghostGroup.clear(true, true)
				this.ghostGroup = this.createGhosts()
				triesRemaining--
				this.updateLivesText()
			}
			
		}
	}

	private updateLivesText() {
        this.livesText.setText('Lives: ' + triesRemaining)
    }

	private handlePlayerEatPowerDot(obj1: Phaser.GameObjects.GameObject, obj2: Phaser.GameObjects.GameObject)
	{
		if (!this.hero)
		{
			return
		}
		this.hero.eatPowerDot(obj2 as Phaser.Physics.Arcade.Sprite)
	}

	private processPlayerEatDot(obj1: Phaser.GameObjects.GameObject, obj2: Phaser.GameObjects.GameObject)
	{
		if (!this.hero)
		{
			return false
		}
		
		return this.hero.canEatDot(obj2 as Phaser.Physics.Arcade.Sprite)
		
	}

	private handlePlayerEatDot(obj1: Phaser.GameObjects.GameObject, obj2: Phaser.GameObjects.GameObject)
	{
		obj2.destroy(true)
		eatDotCount++
		if(eatDotCount == 118) {
			if(borkTrait == 'Junk Collector'){
				triesRemaining++
				setTriesRemaining(triesRemaining)
			} else {
				setTriesRemaining(triesRemaining)
			}
			this.livesText.destroy()
			this.add.text(256, 194, 'You Win!', { fontFamily: 'Arial', fontSize: '24px', color: '#ffffff' })
			this.scene.pause()
			handleGameEnd()
			setTimeout(() => {
                this.scene.stop()
            }, 10000)
		}
	}

	createMobileControls() {
		const controlWidth = 608
		const controlHeight = 73 
		const buttonWidth = 150
		const buttonHeight = 64
		const padding = 4
	
		// Calculate positions based on the game's dimensions
		const controlAreaX = (this.scale.width - controlWidth) / 2
		const controlAreaY = this.scale.height - controlHeight - 10 // 10 pixels above the bottom of the screen
		const controlArea = this.add.container(controlAreaX + controlWidth / 2, controlAreaY + controlHeight / 2)

		const buttons = this.buttons = {
            up: this.add.rectangle(0, -buttonHeight - padding / 2, buttonWidth, buttonHeight, 0x0000ff).setInteractive(),
            down: this.add.rectangle(0, padding / 2, buttonWidth, buttonHeight, 0x0000ff).setInteractive(),
            left: this.add.rectangle(-buttonWidth - padding, -32, buttonWidth, buttonHeight, 0x0000ff).setInteractive(),
            right: this.add.rectangle(buttonWidth + padding, -32, buttonWidth, buttonHeight, 0x0000ff).setInteractive()
        }

        controlArea.add([this.buttons.up, this.buttons.down, this.buttons.left, this.buttons.right])
	
		// Handler for activating a button
		const activateButton = (direction) => {
			// Deactivate all buttons
			Object.values(buttons).forEach(button => button.setFillStyle(0x0000ff))
			// Activate the new button
			buttons[direction].setFillStyle(0xfe03f0)
			// Update direction state
			Object.keys(this.lastMobileDirection).forEach(key => {
				this.lastMobileDirection[key] = (key === direction)
			})
		}
	
		// Attach event handlers
		Object.entries(buttons).forEach(([direction, button]) => {
			button.on('pointerdown', () => activateButton(direction))
			button.on('pointerover', () => {
				if (this.game.input.activePointer.isDown) {
					activateButton(direction)
				}
			})
			button.on('pointerup', () => {
				button.setFillStyle(0x0000ff)
				this.lastMobileDirection[direction] = false
			})
		})
		
	}
	
	
	
	
	
	update(t: number, dt: number) {
		if (this.hero && this.boardLayer) {
            const velocity = this.hero.body.velocity

            // Reset all buttons to default state first
            Object.values(this.buttons).forEach(button => button.setFillStyle(0x0000ff))

            // Update button state based on hero's velocity
            if (velocity.x > 0) this.buttons.right.setFillStyle(0xfe03f0) // Moving right
            if (velocity.x < 0) this.buttons.left.setFillStyle(0xfe03f0)  // Moving left
            if (velocity.y > 0) this.buttons.down.setFillStyle(0xfe03f0)  // Moving down
            if (velocity.y < 0) this.buttons.up.setFillStyle(0xfe03f0)    // Moving up

            this.hero.handleMovement(dt, { cursors: this.cursors, wsad: this.wsad }, this.boardLayer)
            this.physics.add.overlap(this.hero, this.ghostGroup, this.handleHeroGhostCollision, undefined, this)
        
			// Check mobile input and attempt to move if a direction is pressed
			const mockKey = (isDown: boolean): Phaser.Input.Keyboard.Key => ({
				isDown,
				reset: () => {}, 
			}) as Phaser.Input.Keyboard.Key
	
			if (this.lastMobileDirection.up) {
				this.hero.handleMovement(dt, { cursors: {}, wsad: { up: mockKey(true), down: mockKey(false), left: mockKey(false), right: mockKey(false) } }, this.boardLayer)
			}
			if (this.lastMobileDirection.down) {
				this.hero.handleMovement(dt, { cursors: {}, wsad: { up: mockKey(false), down: mockKey(true), left: mockKey(false), right: mockKey(false) } }, this.boardLayer)
			}
			if (this.lastMobileDirection.left) {
				this.hero.handleMovement(dt, { cursors: {}, wsad: { up: mockKey(false), down: mockKey(false), left: mockKey(true), right: mockKey(false) } }, this.boardLayer)
			}
			if (this.lastMobileDirection.right) {
				this.hero.handleMovement(dt, { cursors: {}, wsad: { up: mockKey(false), down: mockKey(false), left: mockKey(false), right: mockKey(true) } }, this.boardLayer)
			}
		}
	}
	

	private createGhosts()
	{
		this.ghostGroup = this.physics.add.group()
		
		const blinky = this.add.ghost(256, 256)
			.makeRed()
			.enableTargetMarker(true)
		blinky.setAI(new ChaseHeroAI(this.hero!, blinky, this.boardLayer!))
		this.ghostGroup.add(blinky)

		const pinky = this.add.ghost(224, 256)
			.makePink()
			.enableTargetMarker(true)
		pinky.setAI(new InterceptHeroAI(this.hero!, pinky, this.boardLayer!, true))
		this.ghostGroup.add(pinky)

		const inky = this.add.ghost(288, 256)
			.makeTeal()
			.enableTargetMarker(true)
		inky.setAI(new FlankHeroAI(this.hero!, inky, blinky, this.boardLayer!, true))
		this.ghostGroup.add(inky)

		const clyde = this.add.ghost(320, 256)
			.makeOrange()
			.enableTargetMarker(true)

		clyde.setAI(new PlayfullyChaseHeroAI(
			this.hero!,
			clyde,
			this.boardLayer!,
			new ScatterAI(16, this.boardLayer!.height - 16, clyde, this.boardLayer!)
		))
		this.ghostGroup.add(clyde)

		return this.ghostGroup
	}

	private createFromObjectsLayer(layer: Phaser.Tilemaps.ObjectLayer)
	{
		for (let i = 0; i < layer.objects.length; ++i)
		{
			const obj = layer.objects[i]
			switch (obj.name)
			{
				case 'spawn':
				{
					const x = Math.round(obj.x! / 32) * 32
					const y = Math.round(obj.y! / 32) * 32
					this.hero = this.add.hero(x + 16, y + 16, 'game-atlas')
					//this.hero.setAI(new HeroAI())
					this.setupHero(this.boardLayer!)
					break
				}
			}
		}
	}

	private setupHero(board: Phaser.Tilemaps.DynamicTilemapLayer)
	{
		if (!this.hero)
		{
			return
		}

		this.physics.add.collider(this.hero, board)

		this.physics.world.setBounds(0, 0, board.width, board.height)
	}


}


