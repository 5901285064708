import Phaser from 'phaser'

import { heroEvents } from '../events/HeroEvents'
import HeroAI from './HeroAI'

enum Moves
{
	None,
	Left,
	Right,
	Up,
	Down
}

enum HeroState
{
	Normal,
	Powered
}

export default class Hero extends Phaser.Physics.Arcade.Sprite
{
	private spawnPoint: { x: number, y: number }
	private normalSpeed = 100
    private poweredSpeed = 150
    private poweredDuration = 5000
	private normalTint = 0xfffc3b // Original color
    private poweredTint = 0xFF1493 // Pink color for powered state

	private queuedMove = Moves.None
	private lastKeyDown = Moves.None
	private queuedMoveAccumulator = 0

	private heroState = HeroState.Normal
	private poweredAccumulator = 0
	private revertDelay?: Phaser.Time.TimerEvent // Reference to the timer event for reverting state
	private heroAI?: HeroAI

	get isPowered()
	{
		return this.heroState === HeroState.Powered
	}

	get facingVector()
	{
		const vec = new Phaser.Math.Vector2()
		vec.setToPolar(this.rotation)
		return vec
	}

	constructor(scene: Phaser.Scene, x: number, y: number, texture: string)
	{
		super(scene, x, y, texture)
		this.spawnPoint = { x, y }
		this.setTint(this.normalTint)
		this.play('hero-idle')
		this.initializePhysics()
	}

	initializePhysics() {
        this.scene.physics.world.enable(this)
        const body = this.body as Phaser.Physics.Arcade.Body
        body.setCircle(16) // Assuming a circular physics body
        this.setSpeed(this.normalSpeed)
    }

	setAI(ai: HeroAI)
	{
		this.heroAI = ai
	}

	canEatDot(dot: Phaser.Physics.Arcade.Sprite)
	{
		const heroPos = this.body.position

		const body = dot.body as Phaser.Physics.Arcade.Body
		const dotPos = body.position.clone()
		dotPos.x -= body.offset.x
		dotPos.y -= body.offset.y

		return Phaser.Math.Distance.BetweenPointsSquared(heroPos, dotPos) <= 100
	}

	eatPowerDot(dot: Phaser.Physics.Arcade.Sprite)
	{
		if (this.heroState === HeroState.Powered && this.revertDelay) {
            // Reset the timer if already powered
            this.revertDelay.remove(false)
        }
		this.heroState = HeroState.Powered
		this.poweredAccumulator = 0
        this.setSpeed(this.poweredSpeed)
		this.setTint(this.poweredTint) // Set to pink color
		dot.destroy(true)
		heroEvents.emit('powered-start')

		// Set or reset a timeout to revert to normal state
        this.revertDelay = this.scene.time.delayedCall(this.poweredDuration, () => {
            this.revertToNormal()
        }, [], this)
	}

	revertToNormal() {
        this.heroState = HeroState.Normal
        this.setSpeed(this.normalSpeed)
        this.setTint(this.normalTint) // Revert the tint to original color
        heroEvents.emit('powered-end')
		this.revertDelay = undefined
    }

	resetHero() {
		// If the hero is in a powered state, revert to normal
		if (this.heroState === HeroState.Powered) {
			this.revertToNormal()
			heroEvents.emit('powered-end')
		this.revertDelay = undefined
		}
		// Reset position or any other necessary properties
		this.setPosition(this.spawnPoint.x, this.spawnPoint.y)
		this.setVelocity(0, 0) // Stop any movement
		this.play('hero-idle') // Play idle animation if needed

	}
	

	setSpeed(speed: number) {
		const body = this.body as Phaser.Physics.Arcade.Body
        body.maxVelocity.set(speed, speed) // This method sets both x and y max velocity
    }

	preUpdate(t: number, dt: number)
	{
		super.preUpdate(t, dt)

		this.scene.physics.world.wrapObject(this, 32)

		// Check if the hero is effectively at rest
		if (this.body.velocity.lengthSq() === 0) {
			this.play('hero-idle', true)  // Ensure the idle animation is played when not moving
		} else {
			// Optionally, ensure the moving animation is played if there is movement
			this.play('hero-move', true)
		}
	
		if (this.heroState === HeroState.Powered) {
            this.poweredAccumulator += dt
            if (this.poweredAccumulator >= this.poweredDuration) {
                this.revertToNormal()
            }
        }
	}

	handleMovement(dt: number, keys: { cursors: Phaser.Types.Input.Keyboard.CursorKeys, wsad: { up: Phaser.Input.Keyboard.Key, down: Phaser.Input.Keyboard.Key, left: Phaser.Input.Keyboard.Key, right: Phaser.Input.Keyboard.Key } }, boardLayer: Phaser.Tilemaps.DynamicTilemapLayer)
	{
		const threshold = 10
		const currentSpeed = this.heroState === HeroState.Powered ? this.poweredSpeed : this.normalSpeed // Use dynamic speed based on state

		const x = (Math.floor(this.x / 32) * 32) + 16
		const y = (Math.floor(this.y / 32) * 32) + 16
		const vel = this.body.velocity

		if (vel.lengthSq() > 0.2) {
			this.play('hero-move', true)
		} else {
			this.play('hero-idle')
			this.lastKeyDown = Moves.None
		}

		const keysDown = this.getKeysDownState(keys.cursors, keys.wsad)

		if (keysDown.left && vel.x >= 0)
		{
			if (!boardLayer.getTileAtWorldXY(this.x - 32, this.y))
			{
				if (vel.y === 0 || Math.abs(y - this.y) <= threshold)
				{
					this.queuedMove = Moves.Left
				}	
			}
		}
		else if (keysDown.right && vel.x <= 0)
		{
			if (!boardLayer.getTileAtWorldXY(this.x + 32, this.y))
			{
				if (vel.y === 0 || Math.abs(y - this.y) <= threshold)
				{
					this.queuedMove = Moves.Right
				}
			}
		}
		else if (keysDown.up && vel.y >= 0)
		{
			if (!boardLayer.getTileAtWorldXY(this.x, this.y - 32))
			{
				if (vel.x === 0 || Math.abs(x - this.x) <= threshold)
				{
					this.queuedMove = Moves.Up
				}
			}
		}
		else if (keysDown.down && vel.y <= 0)
		{
			if (!boardLayer.getTileAtWorldXY(this.x, this.y + 32))
			{
				if (vel.x === 0 || Math.abs(x - this.x) <= threshold)
				{
					this.queuedMove = Moves.Down
				}
			}
		}

		if (this.queuedMove !== Moves.None)
		{
			this.queuedMoveAccumulator += dt
			if (this.queuedMoveAccumulator >= 200)
			{
				this.queuedMove = Moves.None
				this.queuedMoveAccumulator = 0
			}
		}

		switch (this.queuedMove)
		{
			case Moves.None:
				break

			case Moves.Left:
			{
				if (Math.abs(y - this.y) <= 2)
				{
					this.lastKeyDown = this.queuedMove
					this.queuedMove = Moves.None
				}
				break
			}

			case Moves.Right:
			{
				if (Math.abs(y - this.y) <= 2)
				{
					this.lastKeyDown = this.queuedMove
					this.queuedMove = Moves.None
				}
				break
			}

			case Moves.Up:
			{
				if (Math.abs(x - this.x) <= 2)
				{
					this.lastKeyDown = this.queuedMove
					this.queuedMove = Moves.None
				}
				break
			}

			case Moves.Down:
			{
				if (Math.abs(x - this.x) <= 2)
				{
					this.lastKeyDown = this.queuedMove
					this.queuedMove = Moves.None
				}
				break
			}
		}

		switch (this.lastKeyDown)
		{
			case Moves.Left:
			{
				const y = (Math.floor((this.body.y + 16) / 32) * 32) // + 16
				this.body.y = y
				this.setVelocity(-currentSpeed, 0)
				this.setAngle(180)
				break
			}

			case Moves.Right:
			{
				const y = (Math.floor((this.body.y + 16) / 32) * 32) // + 16
				this.body.y = y
				this.setVelocity(currentSpeed, 0)
				this.setAngle(0)
				break
			}

			case Moves.Up:
			{
				const x = Math.floor((this.body.x + 16) / 32) * 32
				this.body.x = x
				this.setVelocity(0, -currentSpeed)
				this.setAngle(-90)
				break
			}

			case Moves.Down:
			{
				const x = Math.floor((this.body.x + 16) / 32) * 32
				this.body.x = x
				this.setVelocity(0, currentSpeed)
				this.setAngle(90)
				break
			}
		
			default:
				break
		}
	}

	private getKeysDownState(cursors: Phaser.Types.Input.Keyboard.CursorKeys, wasd: { up: Phaser.Input.Keyboard.Key, down: Phaser.Input.Keyboard.Key, left: Phaser.Input.Keyboard.Key, right: Phaser.Input.Keyboard.Key })
	{
		if (this.heroAI)
		{
			return this.heroAI.getKeysDownState()
		}

		return {
			left: cursors.left?.isDown || wasd.left.isDown,
			right: cursors.right?.isDown || wasd.right.isDown,
			up: cursors.up?.isDown || wasd.up.isDown,
			down: cursors.down?.isDown || wasd.down.isDown
		}
	}
	
}

Phaser.GameObjects.GameObjectFactory.register('hero', function (this: Phaser.GameObjects.GameObjectFactory, x: number, y: number, texture: string) {
	const hero = new Hero(this.scene, x, y, texture)

	this.displayList.add(hero)
	this.updateList.add(hero)

	this.scene.physics.world.enableBody(hero, Phaser.Physics.Arcade.DYNAMIC_BODY)

	const body = hero.body as Phaser.Physics.Arcade.Body
	body.setCircle(16)
		.setFriction(0, 0)

	return hero
})

declare global
{
	namespace Phaser.GameObjects
	{
		interface GameObjectFactory
		{
			hero(x: number, y: number, texture: string): Hero
		}
	}
}
